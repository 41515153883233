<template>
  <v-app-bar
    v-if="!hideButtons"
    class="pa-1 pr-2 pl-4 mt-2"
    app
    color="surface"
    dense
    dark
    flat
  >
    <v-row align="center" justify="start">
      <v-btn
        @click="openCategoryModal"
        color="primary"
        variant="flat"
        class="text-body-1 ml-2"
        >{{ categoryModalIsVisible ? $t("close") : $t("menu") }}</v-btn
      >

      <v-col v-if="!isPhone && !isTablet && !hideButtons" class="ma-2">
        <SearchField />
      </v-col>

      <v-col
        v-if="!isPhone && !isTablet && !hideButtons"
        cols="12"
        md="7"
        lg="6"
        xl="5"
        align="end"
        class="pr-6"
      >
        <v-row align="end" justify="end" sm="12">
          <v-col
            align="center"
            justify="center"
            class="pa-0 pb-2"
            v-if="cart.pickupTime !== null && cart.pickupTime !== undefined"
          >
            <p class="bold-1">{{ $t("order_by") }}</p>
            <CountdownTimer :targetDate="cart.pickupTime.orderBefore"
          /></v-col>
          <v-col>
            <v-btn
              color="third"
              class="button text-none"
              @click="openLocationSelection"
              variant="flat"
            >
              <v-icon>mdi-truck</v-icon>
              <p class="ml-2">
                {{
                  cart.pickupTime != undefined
                    ? getDayLabel(cart.pickupTime.pickupAt, false) +
                      " " +
                      formatTime(cart.pickupTime.pickupAt) +
                      " - " +
                      formatTime(cart.pickupTime.pickupBefore)
                    : cart.pickupPoint != undefined
                    ? $t("select_pickup_time")
                    : $t("select_pickup_location")
                }}
              </p>
            </v-btn>
          </v-col>

          <v-col sm="3" lg="3">
            <v-badge :content="totalCartItems" color="red" overlap>
              <v-btn
                variant="flat"
                color="error"
                @click="openCartModal"
                :disabled="$route.name === 'cart'"
                class="text-body-1 pa-4"
                :loading="isCartLoading"
              >
                <v-row align="center" justify="center">
                  <v-icon :size="20">mdi-cart-outline</v-icon>

                  <p class="text-body-1 ml-2">
                    {{ totalCartPrice + " kr" }}
                  </p>
                </v-row>
              </v-btn>
            </v-badge>
          </v-col>
        </v-row>
      </v-col>
      <v-row v-else justify="end" class="mr-4">
        <v-btn
          color="third"
          class="text-body-1"
          @click="openLocationSelection"
          variant="flat"
        >
          <v-icon>mdi-truck</v-icon>
        </v-btn>
        <v-badge :content="totalCartItems" color="red" overlap class="mr-3">
          <v-btn
            variant="flat"
            color="error"
            @click="openCartModal"
            :disabled="$route.name === 'cart'"
            class="text-body-1 pa-4 ml-4"
            :loading="isCartLoading"
          >
            <v-row align="center" justify="center">
              <v-icon :size="20">mdi-cart-outline</v-icon>

              <p class="text-body-1 ml-2">
                {{ totalCartPrice + " kr" }}
              </p>
            </v-row>
          </v-btn>
        </v-badge>
      </v-row>
    </v-row>
  </v-app-bar>

  <!-- Location selection component, positioned just below the header -->

  <!-- Search page component, positioned just below the header 
    <SearchPage />-->
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import CartModal from "@/components/cartLogic/CartModal.vue";
import SearchPage from "@/components/smallDevicesOnly/SearchPhonesTablets.vue";
import LoginModal from "@/components/LoginModal.vue";
import logo from "../assets/images/ns_white.png";
import logo_small from "../assets/images/ns_white_small.png";
import { formatTime, getDayLabel } from "../utils.js";
import { mapFields } from "vuex-map-fields";
import SearchField from "./SearchField.vue";
export default {
  name: "Header",
  components: {
    CartModal,
    SearchPage,
    SearchField,
  },

  data() {
    return {
      logo_small: logo_small,
      logo: logo,
      loginModal: false,
      search: "",
      isPhone: false,
      isTablet: false,
      timerCount: 30,
    };
  },
  computed: {
    ...mapFields(["categoryModalIsVisible", "searchTerm"]),
    ...mapState([
      "isCartLoading",
      "localCart",
      "cart",
      "user",
      "isSearchVisible",
      "location",
    ]),
    ...mapGetters(["isProfileVisible"]),

    hideButtons() {
      if (this.$route.meta.hideButtons != undefined) {
        return this.$route.meta.hideButtons;
      } else {
        return false;
      }
    },
    totalCartPrice() {
      return this.cart.price.products?.toFixed(2) || "0.00";
    },
    totalCartItems() {
      return this.localCart.totalItems;
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "toggleCartModal",
      "userLoggedOut",
    ]),
    formatTime,
    getDayLabel,

    goToLogin() {
      this.loginModal = true;
    },
    openLocationSelection() {
      this.toggleLocationModal();
    },
    openCategoryModal() {
      console.log("category modal...");
      this.setCategoryModal(!this.categoryModalIsVisible);
    },
    openCartModal() {
      this.toggleCartModal();
    },
    handleSearch() {
      if (this.search.trim()) {
        this.searchTerm = this.search;
        this.search = "";
        this.$router.push({
          name: "search",
          params: {
            searchTerm: encodeURIComponent(this.searchTerm.trim()),
          },
        });
      }
    },
    logout() {
      this.userLoggedOut();
    },
    checkDevice() {
      const width = window.innerWidth;
      this.isPhone = width <= 600;
      this.isTablet = width > 600 && width <= 1024;
    },
  },
  mounted() {
    this.checkDevice();
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>

<style scoped>
.field {
  min-height: 20px;
  height: 10px;
  max-height: 20px;
}

.header {
  overflow: visible;
}
.text-field {
  caret-color: black;
}

/* Styles for the logo container */
.logo-container {
  cursor: pointer;
  flex: 0 0 330px; /* Fixed size for logo */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Styles for hiding the logo on phones */
.phone-logo {
  flex: 0 0 80px;
}

/* Styles for the logo on tablets */
.tablet-logo {
  flex: 0 0 240px;
}

/* Styles for the header image */
.header-image {
  max-width: 250px;
  margin: auto 10px auto 40px;
}

/* Styles for the left container with the search field */
.left-container {
  flex-grow: 1; /* Allow this container to grow */
  display: flex;
  align-items: center;
  padding-left: 10px; /* Added padding */
}

/* Styles for the search field */
.search-field {
  width: 100%; /* Take full width of its container */
  background-color: white;
  border-radius: 5px;
  color: black;
}

/* Styles for the right container with action buttons */
.right-container {
  flex-grow: 1; /* Allow this container to grow */
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the right */
  padding-right: 10px; /* Added padding */
}

/* Styles for the search icon */
.search-icon {
  color: white;
  font-size: 32px; /* Larger font size for the search icon */
  margin-right: 10px; /* Margin to the right of the search icon */
}

/* Styles for the cart icon */
.cart-icon {
  padding: 25px;
  border-radius: 5px;
  background-color: #c94631;
}

/* Styles for the cart icon */
.cart-btn >>> .v-btn__underlay {
  background-color: #c94631;
  border-radius: 5px;
}

/* Styles for the profile and dropdown text */
.profile-text,
.dropdown-text {
  margin-right: 10px;
}

/* Uniform margin for buttons */
.profile-btn,
.pickup-btn,
.cart-btn {
  margin: 0 10px;
}

/* Styles for the enlarged profile button */
.profile-btn-large {
  font-size: 20px; /* Increase font size */
  padding: 15px; /* Increase padding */
  margin-right: 20px; /* Move more to the right */
}

/* Dropdown menu styles */
.profile-dropdown-container {
  position: relative;
}

.dropdown-menu {
  margin-top: 25px;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ebebeb; /* Dropdown background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 4px;
  overflow: hidden;
}

.dropdown-item {
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  color: black; /* Dropdown text color */
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-logout-btn {
  background-color: #c94631;
  color: white;
  border: none;
  margin: 10px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
}

.dropdown-logout-btn:hover {
  background-color: darkred;
}

/* Responsive styles for smaller screens */
@media (max-width: 600px) {
  .logo-container {
    flex: 0 0 100px; /* Adjust logo size for phones */
  }
  .dropdown-text,
  .profile-text {
    display: none; /* Hide on small screens */
  }
  .profile-btn,
  .pickup-btn,
  .cart-btn {
    min-width: 48px; /* Touch target size */
  }
}
</style>
