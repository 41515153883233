// PickupPoint.js
import axiosInstance from "./AxiosInstance";

/**
 * API for interacting with pickupPoint related endpoints.
 */
const PickupPointAPI = {
  /**
   * Retrieves all pickup points.
   *
   * @returns {Promise<AxiosResponse>} A promise that resolves to the response containing the pickupPoints.
   */
  getAllPickupPoints: ({ lng = 0, lat = 0 } = {}) => {
    const params = {
      lng,
      lat,
    };
    return axiosInstance.get("/pickupPoint/closest", { params });
  },

  suggestLocation: (email, location) => {
    const body = {
      email: email,
      location: location,
    };
    return axiosInstance.post("/pickupPoint/suggestLocation", body);
  },
};

export default PickupPointAPI;
