<template>
  <div class="autocomplete-wrapper">
    <GMapAutocomplete
      :placeholder="$t('search-location')"
      ref="autoComplete"
      validate-on="submit"
      class="map-auto input-with-icon"
      @place_changed="searchAddress"
      @keydown.enter="$event.target.blur()"
      :value="address.address"
      :options="{
        types: ['address'],
        componentRestrictions: { country: 'swe' },
      }"
      style="
        width: 100%;
        height: 50px;
        font-size: 16px;
        padding-top: 26px;
        padding-bottom: 26px;
        padding-left: 12px;
        padding-right: 40px; /* For spacing before the icon */
      "
    />
    <!-- Add a suffix icon -->
    <span class="suffix-icon">
      <v-btn
        @click="getNearMe()"
        variant="text"
        color="primary"
        icon="mdi-crosshairs-gps"
        size="small"
      ></v-btn>
    </span>
  </div>
</template>
<script>
import { useGeolocation } from "@vueuse/core";

export default {
  setup() {
    const { coords, locatedAt, error, resume, pause } = useGeolocation({
      immediate: false, // Prevents immediate triggering
    });
    return { coords, locatedAt, error, resume, pause };
  },
  watch: {
    async coords(newVal, oldVal) {
      console.log(newVal);
      console.log(oldVal.latitude);
      if (
        newVal.latitude < 100 &&
        newVal.longitude < 100 &&
        this.getNearby == true
      ) {
        this.reverseGeocode(newVal.latitude, newVal.longitude);
      }
    },
  },
  methods: {
    async getNearMe() {
      this.resume();
      this.getNearby = true;
      console.log(this.coords);
      if (
        this.coords.latitude < 100 &&
        this.coords.longitude < 100 &&
        this.getNearby == true
      ) {
        this.reverseGeocode(this.coords.latitude, this.coords.longitude);
      }
    },
    async searchAddress(place) {
      this.getNearby = false;
      console.log(place);
      this.setAddress(place);
    },
    async setAddress(place) {
      let street_nr = "";
      let address_name = "";
      for (const comp of place.address_components) {
        for (const typeObj of comp.types) {
          const type = typeObj;
          if (type === "street_number") {
            street_nr = comp.long_name;
          } else if (type === "route") {
            console.log("Postal Code:", comp.long_name);
            address_name = comp.long_name;
          } else if (type === "postal_code") {
            console.log("Postal Code:", comp.long_name);
            this.address.postalCode = comp.long_name;
          } else if (type === "postal_town") {
            console.log("Locality:", comp.long_name);
            this.address.city = comp.long_name;
          } else if (type === "country") {
            console.log("country:", comp.long_name);
            this.address.country = comp.long_name;
          }
          // Add more if statements for other types as needed
        }
      }
      this.address.address =
        address_name + " " + street_nr + ", " + this.address.city;
      console.log(place.geometry.location.lat);
      this.address.coordinates.lat =
        place.geometry.location.lat < 100
          ? place.geometry.location.lat
          : place.geometry.location.lat();
      this.address.coordinates.lng =
        place.geometry.location.lng < 100
          ? place.geometry.location.lng
          : place.geometry.location.lng();

      if (
        this.address.coordinates.lat < 100 &&
        this.address.coordinates.lng < 100
      ) {
        this.$emit(
          "updateCoords",
          this.address.coordinates.lat,
          this.address.coordinates.lng
        );
      }
    },
    async reverseGeocode(lat, lng) {
      const apiKey = "AIzaSyDlADhW9bo3UKpk9OjS_eFUwuO6GNF6wqw";
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.results && data.results.length > 0) {
          this.setAddress(data.results[0]);
        } else {
          return "No address found";
        }
      } catch (error) {
        console.error("Error:", error);
        return "Error fetching address";
      }
    },
  },
  data() {
    return {
      getNearby: false,
      address: {
        coordinates: {
          lng: 200,
          lat: 200,
        },
      },
    };
  },
};
</script>
<style>
.map-auto {
  border: 1px solid #999; /* Replace #999 with your desired color */
  border-radius: 4px;
}

.pac-container {
  z-index: 9999999 !important;
}
#pac-input {
  background-color: #fff;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  width: 300px;
}

/* Style the autocomplete dropdown */
.pac-container {
  background-color: #f5f5f9;
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
  font-size: 14px;
  width: inherit !important;
  border-radius: 5px;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}
/* Style individual autocomplete suggestions */
.pac-item {
  padding: 10px;
  width: inherit !important;
  font-size: medium;
}
.pac-item-query {
  font-size: medium;
}
.pac-item:hover {
  background-color: #ececec;
}
.pac-icon {
  width: 0px;
}

/* Style the input field when it's focused */
#pac-input:focus {
  border-color: #e60404;
}
.autocomplete-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-with-icon {
  position: relative;
  width: 100%;
}

.suffix-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}
</style>
