/**
 * Vuex Store for managing state in the application.
 *
 * State:
 * - location: Object holding storeId and pickupLocation, persisted in localStorage.
 * - userQuery: Contains user's query parameters like bannerId, categoryName, etc.
 * - productModalState: State for managing the visibility of the product details modal.
 * - locationModalState: State for managing the visibility of the location selection modal.
 * - cartModalState: State for managing the visibility of the cart modal.
 * - searchModalState: State for managing the visibility of the search modal.
 * - timeSelectionModalState: State for managing the visibility of the time selection modal.
 * - cartItems: Object representing the items currently in the user's cart.
 * - cartChanges: Array tracking changes to the cart for debounced API updates.
 * - isCartLoading: Boolean indicating if the cart is currently being updated.
 * - categories: Array of product categories, initially loaded from localStorage and then updated from the server.
 * - pickupPoints: Array of pickup points fetched from the server.
 * - pickupTimes: Array of available pickup times fetched from the server.
 * - user: Object containing user information, persisted in localStorage.
 * - token: String containing the JWT token, persisted in localStorage.
 * - refreshToken: String containing the refresh token, persisted in localStorage.
 *
 * Mutations:
 * - setLocation: Sets the storeId and pickupLocation, and persists them in localStorage.
 * - setPickupTimes: Sets the available pickup times.
 * - clearLocation: Clears the storeId and pickupLocation from state and localStorage.
 * - showProductModal: Shows the product details modal.
 * - hideProductModal: Hides the product details modal.
 * - toggleLocationModal: Toggles the visibility of the location selection modal.
 * - hideLocationModal: Hides the location selection modal.
 * - toggleCartModal: Toggles the visibility of the cart modal.
 * - hideCartModal: Hides the cart modal.
 * - toggleSearchModal: Toggles the visibility of the search modal.
 * - hideSearchModal: Hides the search modal.

 * - addToCart: Adds a product to the cart.
 * - removeFromCart: Removes a product from the cart.
 * - clearCart: Clears the cart and updates localStorage.
 * - syncLocalCartWithServer: Synchronizes the local cart with the server response.
 * - setCartLoading: Sets the loading state for the cart.
 * - setCategories: Sets the categories array and updates localStorage.
 * - setPickupPoints: Sets the pickup points array.
 * - setUser: Sets the user information and updates localStorage.
 * - setToken: Sets the JWT token and updates localStorage.
 * - setRefreshToken: Sets the refresh token and updates localStorage.
 * - clearAuthData: Clears the authentication data from the state and localStorage.
 *
 * Actions:
 * - initializeLocation: Loads the storeId and pickupLocation from localStorage upon store creation.
 * - updateSelectedPickupTime: Updates the selected pickup time.
 * - closeProductModal: Hides the product details modal.
 * - toggleLocationModal: Toggles the visibility of the location selection modal.
 * - hideLocationModal: Hides the location selection modal.
 * - toggleCartModal: Toggles the visibility of the cart modal.
 * - toggleSearchModal: Toggles the visibility of the search modal.
 * - hideSearchModal: Hides the search modal.
 * - selectLocation: Sets the storeId and pickupLocation.
 * - addToCart: Adds a product to the cart and updates the server.
 * - removeFromCart: Removes a product from the cart and updates the server.
 * - clearCart: Clears the cart by sending a request to the server.
 * - fetchCategories: Fetches the categories from the server and updates the state and localStorage.
 * - initializeApp: Initializes the application state, including fetching categories and setting up location.
 * - initializeCart: Initializes a new cart when a store is selected.
 * - userLoggedIn: Handles user login, storing user info and tokens.
 * - updateToken: Updates the JWT token.
 * - userLoggedOut: Handles user logout, clearing authentication data.
 *
 * Getters:
 * - location: Provides access to the location state.
 * - userQuery: Provides access to the userQuery state.
 * - productModalState: Provides access to the productModalState.
 * - locationModalState: Provides access to the locationModalState.
 * - cartItems: Provides access to the cartItems state.
 * - isCartVisible: Indicates if the cart modal is visible.
 * - isSearchVisible: Indicates if the search modal is visible.
 * - categories: Provides access to the categories state.
 * - pickupPoints: Provides access to the pickupPoints state.
 * - pickupTimes: Provides access to the pickupTimes state.
 */

import { createStore } from "vuex";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import PickupPointAPI from "@/api/PickupPointAPI";
import PickupTimeAPI from "@/api/PickupTimeAPI";
import CartAPI from "@/api/CartAPI";
import UserAPI from "@/api/UserAPI";
import { auth } from "@/plugins/firebaseconfig";

import { getField, updateField } from "vuex-map-fields";

let debounceTimer;
const debounceDelay = 1000; // 1 second delay for debouncing actions

const store = createStore({
  state: {
    location: {
      storeId: localStorage.getItem("storeId") || "",
      pickupLocation: localStorage.getItem("pickupLocation") || null,
    },
    userQuery: {
      homePage: true,
      bannerId: null,
      textBannerId: null,
      categoryName: null,
      searchTerm: null,
      userFilter: {},
    },
    currentLang: localStorage.getItem("currentLang") || "sv",
    locationModalIsVisible: false,
    cartModalIsVisible: false,
    categoryModalIsVisible: window.innerWidth >= 768,
    searchTerm: "",
    cart: JSON.parse(localStorage.getItem("cart")) || {},
    localCart: {
      items: [],
      total: 0,
      totalItems: 0,
    },
    cartChanges: [],
    isCartLoading: false,
    categories: JSON.parse(localStorage.getItem("categories")) || [],
    pickupPoints: [],
    pickupTimes: [],
    user: JSON.parse(localStorage.getItem("user")) || null,
    token: localStorage.getItem("jwtToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
  },

  mutations: {
    updateField,
    /**
     * Sets the storeId and pickupLocation in the state and persists them in localStorage.
     * Dispatches initializeCart if the storeId was previously null.
     * @param {Object} state - The current state of the Vuex store.
     * @param {Object} payload - The payload containing storeId and pickupLocation.
     */
    async setLocation(state, { storeId, pickupLocation, pickupTime }) {
      state.location.storeId = storeId;
      state.location.pickupLocation = pickupLocation;
      localStorage.setItem("storeId", storeId);
      localStorage.setItem("pickupLocation", pickupLocation);
    },

    /**
     * Clears the storeId and pickupLocation from the state and localStorage.
     * @param {Object} state - The current state of the Vuex store.
     */
    clearLocation(state) {
      state.location.storeId = null;
      state.location.pickupLocation = null;
      localStorage.removeItem("storeId");
      localStorage.removeItem("pickupLocation");
      state.locationModalState.isVisible = true;
    },

    /**
     * Toggles the visibility of the cart modal and hides other modals.
     * @param {Object} state - The current state of the Vuex store.
     */
    toggleCartModal(state) {
      state.cartModalIsVisible = !state.cartModalIsVisible;
      state.locationModalIsVisible = false;
    },

    /**
     * Toggles the visibility of the cart modal and hides other modals.
     * @param {Object} state - The current state of the Vuex store.
     */
    setCategoryModal(state, modal) {
      state.categoryModalIsVisible = modal;
    },

    /**
     * Toggles the visibility of the cart modal and hides other modals.
     * @param {Object} state - The current state of the Vuex store.
     */
    setCurrentLang(state, lang) {
      state.currentLang = lang;
      console.log(lang);
      localStorage.setItem("currentLang", lang);
    },
    /**
     * Toggles the visibility of the cart modal and hides other modals.
     * @param {Object} state - The current state of the Vuex store.
     */
    toggleLocationModal(state) {
      state.cartModalIsVisible = false;
      state.locationModalIsVisible = !state.locationModalIsVisible;
    },

    changeLocalCart(state, { id, quantity }) {
      console.log(id);
      console.log(quantity);
      const i = state.localCart.items.findIndex((element) => element.id == id);
      console.log(i);
      if (i != -1) {
        const oldQuantity = state.localCart.items[i].quantity;
        if (quantity == 0) {
          state.localCart.items.splice(i, 1);
        } else {
          state.localCart.items[i].quantity = quantity;
        }
      } else {
        state.localCart.items.push({
          id: id,
          quantity: quantity,
        });
      }
      state.localCart.totalItems = state.localCart.items.reduce(
        (accumulator, item) => {
          return accumulator + item.quantity;
        },
        0
      );
      console.log(state.localCart);
    },

    SET_CART(state, newCart) {
      localStorage.setItem("cart", JSON.stringify(newCart));
      console.log(JSON.parse(localStorage.getItem("cart")));
      state.cart = newCart;
    },
    /**
     * Synchronizes the local cart with the server response.
     * @param {Object} state - The current state of the Vuex store.
     * @param {Object} newCart - The new cart object received from the server.
     */
    syncLocalCartWithServer(state) {
      state.localCart.total = state.cart.price.products;
      state.localCart.totalItems = state.cart.totalItems;
      state.localCart.items = [];
      for (const prod of state.cart.productVariants) {
        state.localCart.items.push({
          id: prod.productVariant._id,
          quantity: prod.quantity,
        });
      }
    },

    /**
     * Sets the loading state for the cart.
     * @param {Object} state - The current state of the Vuex store.
     * @param {boolean} isLoading - The loading state to be set.
     */
    setCartLoading(state, isLoading) {
      state.isCartLoading = isLoading;
    },

    /**
     * Sets the categories array and updates localStorage.
     * @param {Object} state - The current state of the Vuex store.
     * @param {Array} categories - The categories array to be set.
     */
    setCategories(state, categories) {
      state.categories = categories;
      localStorage.setItem("categories", JSON.stringify(categories));
    },

    /**
     * Sets the user information and updates localStorage.
     * @param {Object} state - The current state of the Vuex store.
     * @param {Object} user - The user information to be set.
     */
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },

    /**
     * Sets the JWT token and updates localStorage.
     * @param {Object} state - The current state of the Vuex store.
     * @param {string} token - The JWT token to be set.
     */
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("jwtToken", token);
    },

    /**
     * Sets the refresh token and updates localStorage.
     * @param {Object} state - The current state of the Vuex store.
     * @param {string} refreshToken - The refresh token to be set.
     */
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      localStorage.setItem("refreshToken", refreshToken);
    },

    /**
     * Clears the authentication data from the state and localStorage.
     * @param {Object} state - The current state of the Vuex store.
     */
    clearAuthData(state) {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      localStorage.removeItem("user");
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("refreshToken");
    },
  },

  actions: {
    async setLocation(
      { state, commit, dispatch },
      { storeId, pickupLocation, pickupTime }
    ) {
      await commit("setLocation", { storeId, pickupLocation, pickupTime });
      if (
        state.location.pickupLocation != null &&
        state.location.pickupLocation != "null" &&
        (state.location.storeId != state.cart.storeId ||
          state.location.pickupLocation != state.cart.pickupPoint) &&
        state.cart._id != null
      ) {
        const updatebody = {
          storeId: state.location.storeId,
          pickupPoint: state.location.pickupLocation,
        };
        console.log(updatebody);
        const newCart = await CartAPI.moveCart(updatebody);

        await dispatch("SET_CART", newCart.data);
      }
      if (pickupTime != null) {
        const newCart = await CartAPI.changePickupTime(pickupTime);
        console.log(newCart);
        await dispatch("SET_CART", newCart.data);
      }
    },

    async SET_CART({ commit, dispatch }, newCart) {
      if (newCart.status == "archived") {
        await dispatch("createCart");
        return;
      }
      await commit("SET_CART", newCart);
    },
    /**
     * Initializes the location by loading the storeId and pickupLocation from localStorage.
     * @param {Object} context - The Vuex action context.
     */
    async initializeLocation({ commit, dispatch }) {
      console.log(process.env.VITE_APP_STOREID);
      const storeId =
        localStorage.getItem("storeId") != null
          ? localStorage.getItem("storeId")
          : process.env.VITE_APP_STOREID;

      /*const pickupLocation =
        localStorage.getItem("pickupLocation") != null
          ? localStorage.getItem("pickupLocation")
          : "667e6eff8f48b18783b69f75";*/
      if (storeId) {
        await dispatch("setLocation", {
          storeId,
          pickupLocation: null,
          pickupTime: null,
        });
      }
    },

    /**
     * Toggles the visibility of the location selection modal.
     * @param {Object} context - The Vuex action context.
     */
    toggleLocationModal({ commit }) {
      commit("toggleLocationModal");
    },

    /**
     * Toggles the visibility of the cart modal.
     * @param {Object} context - The Vuex action context.
     */
    toggleCartModal({ commit }) {
      commit("toggleCartModal");
    },

    setCategoryModal({ commit }, modal) {
      commit("setCategoryModal", modal);
    },

    async setCurrentLang({ commit, dispatch, state }, lang) {
      console.log("setting lang");
      await commit("setCurrentLang", lang);
      await dispatch("fetchCategories");
      const resp = await CartAPI.fetchCart(state.cart._id);
      const storedCart = resp.data;
      console.log(storedCart);
      await dispatch("SET_CART", storedCart);
    },
    /**
     * Sets the storeId and pickupLocation.
     * @param {Object} context - The Vuex action context.
     * @param {Object} payload - The payload containing storeId and pickupLocation.
     */
    async selectLocation(
      { dispatch, commit },
      { storeId, pickupLocation, pickupTime }
    ) {
      console.log(pickupTime);
      await dispatch("setLocation", { storeId, pickupLocation, pickupTime });
    },

    async createCart({ commit, dispatch }) {
      const newCart = await CartAPI.createCart();
      await dispatch("SET_CART", newCart.data);
      await commit("syncLocalCartWithServer");
    },

    changeLocalCart({ commit, state }, { id, quantity }) {
      console.log(id);
      console.log(quantity);
      commit("changeLocalCart", { id, quantity });
    },

    async removePromoCode({ commit, state, dispatch }) {
      const response = await CartAPI.removePromotion();
      const updatedCart = response.data;
      dispatch("SET_CART", updatedCart);
    },

    async validatePromoCode({ commit, state, dispatch }, { code }) {
      const response = await CartAPI.validatePromoCode({ code: code });
      const updatedCart = response.data;
      dispatch("SET_CART", updatedCart);
    },

    async changeCart({ commit, state, dispatch }, { id, quantity }) {
      try {
        commit("setCartLoading", true);
        const response = await CartAPI.changeProductInCart(state.cart._id, {
          productId: id,
          quantity: quantity,
        });
        const updatedCart = response.data;
        if (updatedCart.status == "archived") {
          await dispatch("createCart");
          await dispatch("changeCart", { id, quantity });
        }
        dispatch("SET_CART", updatedCart);
        commit("setCartLoading", false);
        console.log("finish updating cart");
      } catch (error) {
        console.log(error);
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },

    /**
     * Clears the cart by sending a request to the server and updates the state with the new cart.
     * @param {Object} context - The Vuex action context.
     */
    async clearCart({ commit, dispatch, state }) {
      if (state.cart.totalItems === 0) {
        return;
      }
      commit("setCartLoading", true);
      const updatedCart = await CartAPI.emptyCart(state.cart._id);
      dispatch("SET_CART", updatedCart.data);
      commit("syncLocalCartWithServer");
      commit("setCartLoading", false);
    },

    async reserveCart({ commit, dispatch, state }) {
      const updatedCart = await CartAPI.reserveCart();
      dispatch("SET_CART", updatedCart.data);
      commit("syncLocalCartWithServer", updatedCart.data);
    },

    async unReserveCart({ commit, dispatch, state }) {
      const updatedCart = await CartAPI.unReserveCart();
      dispatch("SET_CART", updatedCart.data);
      commit("syncLocalCartWithServer", updatedCart.data);
    },
    /**
     * Checks out the cart and updates the state with the new cart object returned from the server.
     * Ensures userId is set before checkout.
     * @param {Object} context - The Vuex action context.
     */
    async checkoutCart({ commit, dispatch, state }) {
      try {
        commit("setCartLoading", true);

        // Ensure userId is set before checkout
        if (!state.cart.userId || state.cart.userId == "") {
          const updatedCart = await CartAPI.updateCart({
            userId: state.user._id,
          });

          dispatch("SET_CART", updatedCart.data);
          commit("syncLocalCartWithServer", updatedCart.data);
        }

        const newCart = await CartAPI.checkoutCart();
        console.log("Checkout successful:", newCart.data);
        dispatch("SET_CART", newCart.data);
        commit("syncLocalCartWithServer", newCart.data);
        // Handle post-checkout logic here (e.g., redirecting to a success page)
      } catch (error) {
        console.error("Error during checkout:", error);
      } finally {
        commit("setCartLoading", false);
      }
    },

    /**
     * Fetches the categories from the server and updates the state and localStorage.
     * @param {Object} context - The Vuex action context.
     */
    async fetchCategories({ commit }) {
      try {
        const response = await CategoryProductAPI.getAllCategories();
        commit("setCategories", response.data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    },

    /**
     * Initializes the application state, including fetching categories and setting up location.
     * @param {Object} context - The Vuex action context.
     */
    async initializeApp({ dispatch, state }) {
      await dispatch("initializeLocation");
      await dispatch("initializeCart");
      await dispatch("fetchCategories");

      this.watch(
        () => state.location.storeId,
        (newStoreId) => {
          if (newStoreId) {
            dispatch("fetchCategories");
          }
        }
      );
    },

    /**
     * Initializes a new cart when a store is selected.
     * If there is an existing cart in localStorage, it syncs with the server.
     * Otherwise, it creates a new cart.
     * @param {Object} context - The Vuex action context.
     */
    async initializeCart({ dispatch, state, commit }) {
      try {
        console.log(state.cart);
        if (state.cart && state.cart._id) {
          const resp = await CartAPI.fetchCart(state.cart._id);
          const storedCart = resp.data;
          console.log(storedCart);
          await dispatch("SET_CART", storedCart);
          await commit("syncLocalCartWithServer");
        } else {
          console.log("creating new cart..");
          await dispatch("createCart");
        }
      } catch (error) {
        console.error("Error initializing cart:", error);
      }
    },

    /**
     * Handles user login, storing user info and tokens in the state and localStorage.
     * @param {Object} context - The Vuex action context.
     * @param {Object} payload - The payload containing user info, token, and refreshToken.
     */
    async userLoggedIn(
      { commit, state, dispatch },
      { user, token, refreshToken }
    ) {
      commit("setUser", user);
      commit("setToken", token);
      commit("setRefreshToken", refreshToken);

      // If cart exists and is missing userId, update it
      console.log("user", user);
      if (state.cart._id) {
        try {
          const updatedCart = await CartAPI.updateCart({ userId: user._id });
          dispatch("SET_CART", updatedCart.data);
        } catch (error) {
          console.error("Error updating cart userId:", error);
        }
      }
    },

    /**
     * Updates the JWT token.
     * @param {Object} context - The Vuex action context.
     * @param {string} token - The new JWT token.
     */
    async updateToken({ commit }, token) {
      commit("setToken", token);
    },

    /**
     * Handles user logout, clearing authentication data from the state and localStorage.
     * @param {Object} context - The Vuex action context.
     */
    userLoggedOut({ commit }) {
      commit("clearAuthData");
      auth.signOut();
    },
  },

  getters: {
    getField,
    /**
     * Provides access to the location state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The location state.
     */
    location: (state) => state.location,

    /**
     * Provides access to the location state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The location state.
     */
    locationModalIsVisible: (state) => state.locationModalIsVisible,

    /**
     * Provides access to the location state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The location state.
     */
    cartModalIsVisible: (state) => state.cartModalIsVisible,

    categoryModalIsVisible: (state) => state.categoryModalIsVisible,
    /**
     * Provides access to the userQuery state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The userQuery state.
     */
    userQuery: (state) => state.userQuery,
    categoryModalIsVisible: (state) => state.categoryModalIsVisible,
    /**
     * Provides access to the cart state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The cart state.
     */
    cart: (state) => state.cart,

    /**
     * Provides access to the local cart state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The local cart state.
     */
    localCart: (state) => state.localCart,
    /**
     * Provides access to the categories state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Array} The categories state.
     */
    categories: (state) => state.categories,

    /**
     * Provides access to the pickupPoints state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Array} The pickupPoints state.
     */
    pickupPoints: (state) => state.pickupPoints,

    /**
     * Provides access to the pickupTimes state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Array} The pickupTimes state.
     */
    currentLang: (state) => state.currentLang,
    /**
     * Provides access to the pickupTimes state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Array} The pickupTimes state.
     */
    pickupTimes: (state) => state.pickupTimes,

    searchTerm: (state) => state.searchTerm,
  },
});

export default store;
