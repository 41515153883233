<template>
  <v-container fluid
    ><v-col>
      <p class="bold-h1">{{ $t("find-pickup-locations") }}</p>
      <v-col cols="12" class="mt-6 pa-0" md="6">
        <searchAddress @updateCoords="updateCoords"
      /></v-col>
      <v-row v-if="loading" align="center" justify="center" class="mt-5">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>

      <v-col v-elsecols="12" class="pa-0">
        <GMapMap
          :center="center"
          :zoom="5"
          :options="options"
          ref="mapRef"
          map-type-id="terrain"
          style="height: 450px; margin-top: 20px; border-radius: 10px"
          :draggable="true"
        >
          <GMapCluster :renderer="renderer">
            <GMapMarker
              v-for="mark in markers"
              :position="mark"
              :icon="{
                url: mark.openInfoWindow
                  ? mark.selectedBit
                  : mark.unselectedBit,
                scaledSize: { width: 25, height: 25 },
                labelOrigin: { x: 16, y: -10 },
              }"
              @click="clickedOnMark(mark)"
              :clickable="true"
            >
              <GMapInfoWindow :opened="mark.openInfoWindow">
                <v-col class="pa-5">
                  <v-row class="pl-2" align="center" justify="space-between"
                    ><p class="bold-h4 pb-2">{{ mark.pickupPoint.name }}</p>
                    <v-btn
                      @click="clickedOnMark(null)"
                      variant="icon"
                      icon="mdi-close"
                      class="ml-3"
                      size="small"
                    ></v-btn
                  ></v-row>
                  <p class="body-2 mt-2">
                    {{
                      mark.pickupPoint.address + ", " + mark.pickupPoint.city
                    }}
                  </p>
                  <v-btn
                    class="mt-4 button text-none"
                    @click="chooseLocation(mark)"
                    >{{ $t("choose-pickup-location") }}</v-btn
                  >
                </v-col></GMapInfoWindow
              ></GMapMarker
            >
          </GMapCluster>
        </GMapMap>
      </v-col>
      <v-card class="mt-10">
        <v-form @submit.prevent="suggestLocation" ref="form">
          <v-col
            ><p class="bold-h3">{{ $t("not-near-you") }}</p>
            <p>
              {{ $t("enter-email-and-area") }}
            </p>
            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  v-model="email"
                  clearable
                  :rules="emailRules"
                  validate-on="submit"
                  color="primary"
                  bg-color="surface"
                  variant="outlined"
                  single-line
                  :placeholder="$t('email')"
                  persistent-hint
              /></v-col>
              <v-col>
                <v-text-field
                  v-model="suggestPlace"
                  clearable
                  :rules="textFieldRules"
                  validate-on="submit"
                  color="primary"
                  bg-color="surface"
                  variant="outlined"
                  single-line
                  :placeholder="$t('suggestion-place')"
                  persistent-hint /></v-col
            ></v-row>
            <v-btn
              type="submit"
              color="primary"
              class="mt-4 button text-none"
              elevation="0"
              >{{ $t("submit") }}</v-btn
            ></v-col
          ></v-form
        ></v-card
      >
    </v-col>
  </v-container>
</template>
<script>
import searchAddress from "@/components/searchAddress.vue";
import PickupPointAPI from "@/api/PickupPointAPI";
import unselectedBit from "../assets/images/unselectedBit.png";
import selectedBit from "../assets/images/selectedBit.png";
import cluster from "../assets/images/cluster.png";
import { mapState, mapActions, mapGetters } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  components: {
    searchAddress,
  },

  setup() {
    const renderer = {
      render({ count, position }) {
        return new google.maps.Marker({
          label: {
            text: String(count),
            color: "white",
            fontSize: "14px",
            fontFamily: "agenda",
          },
          position,
          icon: {
            url: cluster,
            scaledSize: {
              width: 30,
              height: 30,
              equals: () => true,
            },
          },
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
      },
    };
    const { t, locale } = useI18n();
    return { t, locale, renderer };
  },
  methods: {
    ...mapActions(["selectLocation"]),
    async suggestLocation() {
      const { valid } = await this.$refs.form.validate();

      if (!valid) return;

      await PickupPointAPI.suggestLocation(this.email, this.suggestPlace);
      this.email = "";
      this.suggestPlace = "";
      this.$root.vtoast.show({
        message: this.t("we-received-your-response"),
        color: "success",
      });
    },
    chooseLocation(mark) {
      console.log(mark);
      this.selectLocation({
        storeId: mark.pickupPoint.warehouseId,
        pickupLocation: mark.pickupPoint._id,
      });
    },
    async clickedOnMark(mark) {
      this.markers.forEach((e) => {
        if (mark != null && e.id == mark.id) {
          e.openInfoWindow = true;
        } else {
          e.openInfoWindow = false;
        }
      });
    },
    async updateCoords(lat, lng) {
      console.log("lat", lat);
      const mapInstance = this.$refs.mapRef.$mapObject;
      this.center = { lat: lat, lng: lng };
      mapInstance.setCenter(new google.maps.LatLng(lat, lng));
      mapInstance.setZoom(12);
    },
  },
  data() {
    return {
      suggestPlace: "",
      email: "",
      loading: true,
      unselectedBit: unselectedBit,
      cluster: cluster,
      selectedBit: selectedBit,
      infoWindowContent:
        "<div><h1>My Title</h1><p>Additional information here.</p></div>",
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        draggable: true,
        fullscreenControl: false,
      },
      markers: [],
      pickupPoints: [],
      coordinates: { lat: 58.407756, lng: 15.596989 },
      center: { lat: 58.407756, lng: 15.596989 },
      emailRules: [
        (value) => {
          if (value) return true;

          return this.t("email_is_required");
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return this.t("email_must_be_valid");
        },
      ],
      textFieldRules: [
        (value) => {
          if (value) return true;
          return this.t("field_required");
        },
      ],
    };
  },
  async mounted() {
    const resp = await PickupPointAPI.getAllPickupPoints();
    this.pickupPoints = resp.data;
    this.markers = this.pickupPoints.map((item) => {
      // Use Array.find to find the store object with storeID "1"
      return {
        id: item._id,
        lat: item.position.coordinates[1],
        lng: item.position.coordinates[0],
        openInfoWindow: false,
        selectedBit: selectedBit,
        unselectedBit: unselectedBit,
        pickupPoint: item,
      };
    });
    this.loading = false;
  },
};
</script>
<style>
.gm-style-iw-chr {
  height: 0;
  opacity: 0;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
</style>
